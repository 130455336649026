<template>
  <div class="home-banner">
    <XtxCarousel v-if="banners" :carousels="banners" autoPlay />
  </div>
</template>
<script>
import useBanners from "@/hooks/useBanners";
const { minioURL } = window.settings;

export default {
  name: "HomeBanner",
  setup() {
    let banners = useBanners();
    banners = [
      {
        id: "19",
        imgUrl: minioURL + "/" + "threed-model/image/banner1.png",
        hrefUrl: "/category/1",
        type: "1",
      },
      {
        id: "20",
        imgUrl: minioURL + "/" + "threed-model/image/banner10.png",
        hrefUrl: "/category/10",
        type: "1",
      },
      {
        id: "16",
        imgUrl: minioURL + "/" + "threed-model/image/banner2.png",
        hrefUrl: "/category/2",
        type: "1",
      },
    ];
    console.log("banners", banners);

    return {
      banners,
    };
  },
};
</script>
<style scoped lang="less">
.home-banner {
  width: 1240px;
  height: 500px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 98;
  .xtx-carousel {
    :deep(.carousel-btn.prev) {
      left: 270px;
    }
    :deep(.carousel-indicator) {
      padding-left: 250px;
    }
  }
}
</style>
