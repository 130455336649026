<template>
  <HomePanel title="人气推荐" sub-title="人气爆款 不容错过" ref="target">
    <Transition name="fade">
      <ul class="goods-list" v-if="homeHot">
        <li v-for="item in homeHot" :key="item.id">
          <RouterLink :to="`/goods/${item.id}`">
            <img :src="minioURL + '/' + item.thumbUrl" alt="item.name" />
            <p class="name ellipsis">{{ item.name }}</p>
            <p class="desc ellipsis">{{ item.tags }}</p>
            <p class="desc ellipsis">{{ item.extensions }}</p>
          </RouterLink>
        </li>
      </ul>
      <HomeSkeleton v-else />
    </Transition>
  </HomePanel>
</template>

<script>
import HomePanel from "@/views/home/components/HomePanel";
// import useHomeHot from "@/hooks/home/useHomeHot";
import useLazyData from "@/hooks/useLazyData";
import { getHomeHot } from "@/api/home";
import HomeSkeleton from "@/views/home/components/HomeSkeleton";
const { minioURL } = window.settings;
export default {
  name: "HomeHot",
  components: { HomeSkeleton, HomePanel },
  setup() {
    // const homeHot = useHomeHot();
    const { target, result: homeHot } = useLazyData(getHomeHot);
    console.log("---->home hot", homeHot);
    return { homeHot, target, minioURL };
  },
};
</script>
<style scoped lang="less">
.goods-list {
  display: flex;
  justify-content: space-between;
  height: 336px;
  li {
    width: 306px;
    height: 336px;
    .hoverShadow();
    img {
      width: 306px;
    }
    p {
      font-size: 22px;
      padding-top: 12px;
      text-align: center;
    }
    .desc {
      color: #999;
      font-size: 18px;
    }
  }
}
</style>
